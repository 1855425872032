<template>
    <BannerTemplate :bannerSource="bannerSource" :title="`Wachtwoord wijzigen`" :intro="``">
        <v-container>
            <v-row>
                <v-col cols="12" class="py-0">
                    <v-form ref="form" class="ma-2" v-if="!isPasswordChanged"
                            v-model="valid"
                            lazy-validation>
                        <v-text-field
                                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="[rules.required, rules.password]"
                                required
                                color="primary"
                                name="password"
                                :type="showPassword ? 'text' : 'password'"
                                :disabled="isSubmitting"
                                label="Nieuw wachtwoord*"
                                v-model="password"
                                @click:append="showPassword = !showPassword">
                        </v-text-field>
                        <v-text-field
                                :append-icon="showRepeatPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="[rules.confirmPassword, rules.required]"
                                color="primary"
                                required
                                :disabled="isSubmitting"
                                name="repeatPassword"
                                :type="showRepeatPassword ? 'text' : 'password'"
                                label="Nieuw wachtwoord bevestigen*"
                                v-model="repeatPassword"
                                @click:append="showRepeatPassword = !showRepeatPassword">
                        </v-text-field>

                        <v-btn color="primary" block @click="onChangePassword()" class="mt-2 mb-5" :disabled="isSubmitting">
                           Wijzig wachtwoord
                        </v-btn>
                    </v-form>
                    <div class="px-2" v-if="isPasswordChanged">
                        U heeft succesvol uw wachtwoord gewijzid.

                        <v-btn color="primary" block  @click="goToLoginOnly()" class="mt-6 mb-5">
                            Login
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </BannerTemplate>
</template>

<script>
import BannerTemplate from '@/components/common/BannerTemplate';
import DataMixin from "@/mixins/data.mixin";
import RouteMixin from "@/mixins/route.mixin";
import MetaTagsMixin from "@/mixins/metatags.mixin";

export default {
    name: 'WachtwoordWijzigen',
    components: 
    {
        BannerTemplate
    },
    mixins: [DataMixin, RouteMixin, MetaTagsMixin],
    data: () => ({
        bannerSource: require('@/assets/album/Login/login-01.jpg'),
        passwordChangeString: '',
        isPasswordChanged: false,
        valid: true,
        password: '',
        showPassword: false,
        showRepeatPassword: false,
        repeatPassword: '',
        isSubmitting: false
    }),
    mounted () 
    {
        this.$store.commit('setFooterVisibility', true);
        this.passwordChangeString = this.$route.params.passwordChangeString;
    },
    metaInfo() {
        return {
        title: '| Wachtwoord Wijzigen',
        meta: [
            this.getKeywords(`Wachtwoord wijzigen`),
            this.getMetaDescription(`Wachtwoord wijzigen`),
            this.getAuthor(),
            ...this.getSocialMediaMetas(`Wachtwoord wijzigen`, `Wachtwoord wijzigen`,  '', ''),
        ]
        };
    },
    methods: 
    {
        onChangePassword()
        {
            this.valid = this.$refs.form.validate()

            if(this.valid)
            {
                this.isSubmitting = true;
                const request = {
                    passwordChangeString: this.passwordChangeString,
                    newPassword: this.password
                }

                this.$store.dispatch('accountModule/changePassword', request)
                .then((data) => {
                    this.isPasswordChanged = data;
                    this.isSubmitting = false;
                })
                .catch(error => {
                    this.isPasswordChanged = false;
                    this.isSubmitting = false;
                    this.$store.commit('dialog/showDialog', error);
                })
            }
        }
    }
}
</script>
